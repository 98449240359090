import { Link } from "react-router-dom";

const faq = [
  {
    question: "What is this about?",
    answer:
      "NFTfloorprice is a simple tool that sends email alerts once your favorite NFT collections reach a certain floor price.",
  },
  {
    question: "How many alerts can I set up?",
    answer:
      "As many as you’d like while we’re still in beta.",
  },
  {
    question: "How much do you charge?",
    answer:
      "Currently nothing 💸 if you like the service you can help us cover our server costs by donating a small amount here",
    additionalHtml: (<Link className="link-to-donate" to="/donate">https://nftfloorprice.info/donate</Link>)
  },
];

const Faq = ({setHideFooter}) => {

  return (
    <div className="faq-container">
      <h1>WTF?</h1>
      <div className="faq-list">
        {faq.map((qAndA, index) => {
          const { question, answer, additionalHtml } = qAndA;
          return (
            <div key={index}>
              <h3>{question}</h3>
              <div className="faq-answer"><span className="answer-text">{answer}</span> {additionalHtml}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Faq;
