// Following this example:
// => https://dev.to/nazmifeeroz/using-usecontext-and-usestate-hooks-as-a-store-mnm
import React from 'react'
import { useEffect } from "react";

// Initialize Firebase
// init 2 firebase projects:
// (1) nftfloorprice (for analytics)
// (2) shillcoin (for https and callable functions)
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFunctions, httpsCallable } from "firebase/functions";

// initialize firebase app for nftfloorprice firebase project
// (analytics only)
const firebaseConfigNftFloorPrice = {
  apiKey: "AIzaSyDlFl3RsV12a3qUQwQ-Ri9smFraHdE32Qg",
  authDomain: "nft-floor-price.firebaseapp.com",
  projectId: "nft-floor-price",
  storageBucket: "nft-floor-price.appspot.com",
  messagingSenderId: "777158050375",
  appId: "1:777158050375:web:87aa37990241705b162762",
  measurementId: "G-6T2MLYTHHP"
};
const appNftFloorPrice = initializeApp(firebaseConfigNftFloorPrice, "nftfloorprice");
getAnalytics(appNftFloorPrice);

// initialize firebase app for shillcoin
const firebaseConfigShillcoin = {
  apiKey: "AIzaSyAEygIG7B48mvxkm13bcHIzn3kJZqTK1kY",
  authDomain: "shillcoin.firebaseapp.com",
  projectId: "shillcoin",
  storageBucket: "shillcoin.appspot.com",
  messagingSenderId: "265713696512",
  appId: "1:265713696512:web:b57aac094610a4ea09fde5",
};
const appShillcoin = initializeApp(firebaseConfigShillcoin, "shillcoin");
const functions = getFunctions(appShillcoin);
const fetchFloorPrice = httpsCallable(functions, "fetchFloorPrice");
const fetchNftDict = httpsCallable(functions, "fetchNftDict");
const nftfloorpriceFetchDashboard = httpsCallable(functions, "nftfloorpriceFetchDashboard");
const nftfloorpriceOverview = httpsCallable(functions, "nftfloorpriceOverview");

window.fetchNftDict = fetchNftDict;
window.fetchFloorPrice = fetchFloorPrice;
export const AppContext = React.createContext(null)

export default ({ children }) => {
  // helper functions
  const computeIsMobile = () => window.innerWidth <= 850;

  // isMobile
  // viewportWidth
  // nftDict
  // fetchFloorPrice (firebase callable function)
  // fetchNftDict (firebase callable function)
  // nftfloorpriceFetchDashboard (firebase callable function)
  const [isMobile, setIsMobile] = React.useState(computeIsMobile());
  const [viewportWidth, setViewportWidth] = React.useState(window.innerWidth);
  const [nftDict, setNftDict] = React.useState({});

  const handleResizeEvent = () => {
    setViewportWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleResizeEvent);
    return () => {
      window.removeEventListener("resize", handleResizeEvent);
    }
  }, []);

  useEffect(() => {
    async function loadNftDict() {
      const result = await fetchNftDict();
      setNftDict(result.data);
    }
    loadNftDict();
  }, []);

  // update isMobile based on viewportWidth
  useEffect(() => {
    setIsMobile(computeIsMobile());
  }, [viewportWidth])

  const store = {
    isMobile: [isMobile, setIsMobile],
    viewportWidth: [viewportWidth, setViewportWidth],
    nftDict: [nftDict, setNftDict],
    fetchFloorPrice: [fetchFloorPrice],
    fetchNftDict: [fetchNftDict],
    nftfloorpriceFetchDashboard: [nftfloorpriceFetchDashboard],
    nftfloorpriceOverview: [nftfloorpriceOverview],
  }

  return <AppContext.Provider value={store}>{children}</AppContext.Provider>
}


