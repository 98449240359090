import { Link } from "react-router-dom";
import { AiFillWarning } from "react-icons/ai";
import { SiEthereum } from "react-icons/si";

const AlertCreated = () => {
  const query = new URLSearchParams(document.location.search);
  const alertsArr = getAlertsArray(query.get("alerts"));
  const emailOptIn = document.location.href.includes("alertOptIn");
  const firstAlert = query.get("userType") === "new_user";
  const email = query.get("email");
  const pendingVerification = query.get("userType") === "existing_user_not_verified";

  const content = {};
  window.content = content;
  if (emailOptIn) {
    content.title = "Email verified ✅";
    content.subtitle = "You successfully activated your email address. ";
  } else {
    if (firstAlert) {
      content.title = "Almost done... ⏳";
      content.subtitle = "Please confirm your email by clicking the link in the activation email.";
      content.subtitle2 = "No email recieved? Please check your spam folder or contact support.";
    } else {
      content.title = "Alert Created 🎉";
      content.subtitle = "";
    }
  }
  if (!firstAlert) {
    content.conditionalText = alertsArr.length > 0 ? "We will notify you when..." : "";
  }
  if (pendingVerification) {
    content.title = "Verification Pending ⏳"
    content.subtitle = "We are legally not allowed to send you notifications until you confirm your email."
  }

  return (
    <div>
      <div className="error404-container larger alerts">
        <div className="error404-title alerts">
          {content.title}
        </div>
        <div className="error404-text">
          { firstAlert &&
            <>
              <p className="larger-text">{content.subtitle}</p>
              <p className="larger-text">{content.subtitle2}</p>
              <br />
            </>
          }
          { alertsArr.length > 0 && !firstAlert && !pendingVerification &&
            <p className="larger-text">{content.subtitle}{content.conditionalText}<br/>
              <>
                { alertsArr.map(alert => {
                  return (<><span className="bold-text yellow-text">{alert.slug}</span> floor reaches <span className="bold-text yellow-text">{alert.targetPrice}</span> <SiEthereum size={14} color={"yellow"} className="input-icon-left"/><br/></>)
                })}
              </>
            </p>
          }
          { pendingVerification &&
            <>
              <p className="larger-text">{content.subtitle}</p><br/>
              <div className="pending-double-opt-in">
                <AiFillWarning size={70}/>
                <h1>IMPORTANT</h1>
                <p>TO RECIEVE ALERTS PLEASE CONFIRM YOUR EMAIL:<br/>(1) check your mailbox 📫<br />(2) click the activation link.<br/><br/>or contact support at<br/><a className="email-reachout" href="mailto:hello@nftfloorprice.info">hello@nftfloorprice.info</a></p>
              </div>
            </>
          }
        </div>
      </div>
      { firstAlert
        ? (
          <>
            <div className="center-button">
              <div className="main-bttn bttn-primary">
                <a href="mailto:">Open Email</a>
              </div>
            </div>
            <div className="center-button">
              <div className="main-bttn bttn-secondary">
                Contact Support 💌
              </div>
            </div>
          </>
        ) : (
          <div className="center-button">
            <div className="shillcoin-bttn">
              <Link to={`/${email ? `?email=${email}` : ""}`}>{ pendingVerification ? "Home" : "Create Another Alert 🔔" }</Link>
            </div>
          </div>
        )
      }
      <br />
      <br />
      <br />
    </div>
  );
};

function getAlertsArray(alertsStr) {
  try {
    return alertsStr.split(",").map(str => {
      const [ slug, targetPriceStr ] = str.split(":");
      return {
        slug: slug,
        targetPrice: Number(targetPriceStr),
      }
    });
  } catch (err) {
    return [];
  }
}

export default AlertCreated;
