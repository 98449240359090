// import React from "react";
import React from "react";
import { AppContext } from '../utils/AppContext'
import CreateAlertForm from "./Home/CreateAlertForm";

const Home = () => {

  const previewNfts = [
    {
      logoUrl: "https://lh3.googleusercontent.com/LIov33kogXOK4XZd2ESj29sqm_Hww5JSdO7AFn5wjt8xgnJJ0UpNV9yITqxra3s_LMEW1AnnrgOVB_hDpjJRA1uF4skI5Sdi_9rULi8=s200",
      name: "Cool Cats",
      slug: "cool-cats-nft",
    },
    {
      logoUrl: "https://lh3.googleusercontent.com/Ju9CkWtV-1Okvf45wo8UctR-M9He2PjILP0oOvxE89AyiPPGtrR3gysu1Zgy0hjd2xKIgjJJtWIc0ybj4Vd7wv8t3pxDGHoJBzDB=s120",
      name: "Bored Ape Yacht Club",
      slug: "boredapeyachtclub",
    },
    {
      logoUrl: "https://lh3.googleusercontent.com/K-IsvkP8Ehu12svL1fsZTMxuB31Mf5C36FvC9CDdYwtrF0dxv-A9O58fVJVnsixUlHpmYguQU9-_9mM2xEZl-bSTLbz082dX0xF3oQ=w355",
      name: "WE ARE KLOUD",
      slug: "we-are-kloud",
    },
    {
      logoUrl: "https://lh3.googleusercontent.com/LIpf9z6Ux8uxn69auBME9FCTXpXqSYFo8ZLO1GaM8T7S3hiKScHaClXe0ZdhTv5br6FE2g5i-J5SobhKFsYfe6CIMCv-UfnrlYFWOM4=s130",
      name: "CyberKongz",
      slug: "cyberkongz",
    },
    {
      logoUrl: "https://lh3.googleusercontent.com/lHexKRMpw-aoSyB1WdFBff5yfANLReFxHzt1DOj_sg7mS14yARpuvYcUtsyyx-Nkpk6WTcUPFoG53VnLJezYi8hAs0OxNZwlw6Y-dmI=s200",
      name: "Mutant Ape Yacht Club",
      slug: "mutant-ape-yacht-club",
    },
    {
      logoUrl: "https://lh3.googleusercontent.com/cGKkMxKQ-7J0A4mZvcPqr2LKvGqIVsXjzGqpfxRNb4yVr-zTycPJ0kznkwKqDyUTg--A32oW8cI32BQBl6hrbM-frn1__o544ECpCg=s200",
      name: "Lostpoets",
      slug: "lostpoets",
    },
    {
      logoUrl: "https://lh3.googleusercontent.com/hd-_u-7mKHpNQCUMsErZ3BFWUx7w8aKk0fajLdfjea-NEwo-DB0GVi0iS4Y0o7xC2_wlmYNU4xeMOGJxzdduXYIle5OJpUX_dgdnsw=s120",
      name: "Digital Abstraction by Zhannet Podobed",
      slug: "digitalabstraction",
    },
    {
      logoUrl: "https://lh3.googleusercontent.com/WNd3NNCiOAkD3rUNCau9qXDPQsnH3ZA8PcDMhlsfLnK6J1vSNpJnPFCjA-_l03LbCxwAYC1Mj8IcRQch7YMTDLnn_c-jAQ03RWRdEw=s200",
      name: "Supducks",
      slug: "supducks",
    },
    {
      logoUrl: "https://lh3.googleusercontent.com/JWxVF0URG7BU9LMVWhExzlQ--jhis6lhVtZmvCX7KB4AcnL9I6RlF2LgrC3OHO72snQEVfEp_Jd12eFCK_PhIAVOgU_FvJRaW7ezDw=w355",
      name: "Deadfellaz",
      slug: "deadfellaz",
    },
    {
      logoUrl: "https://lh3.googleusercontent.com/6X867ZmCsuYcjHpx-nmNkXeHaDFd2m-EDEEkExVLKETphkfcrpRJOyzFxRQlc-29J0e-9mB9uDGze0O9yracSA9ibnQm2sIq5i2Yuw=s200",
      name: "Hashmasks",
      slug: "hashmasks",
    },
  ]

  // LOAD GLOBAL STATES
  const { ["isMobile"]: [isMobile, setIsMobile] } = React.useContext(AppContext);
  const { ["viewportWidth"]: [viewportWidth, setViewportWidth] } = React.useContext(AppContext);

  // only show first 3 previews on mobile
  const previewNftsCapped = viewportWidth > 1000 ? previewNfts : previewNfts.slice(0,3);
  return (
    <div className="home-container grid-container">
      <div className="grid-left grid-item">
        <div>
          <div className="item item-description">
            <h1 className="main-page-title">NFT floor price<br></br><span className="element">email alerts</span></h1>
            <NftPreviews previewNftsCapped={previewNftsCapped}/>
          </div>
        </div>
      </div>
      <div className="grid-right grid-item">
        <CreateAlertForm/>
      </div>
    </div>
  );
};

const NftPreviews = ({previewNftsCapped}) => {
  const tooltip = previewNftsCapped.length > 3 ? "on" : "off"; // disable tooltip on mobile
  return (
    <div className="item item-youtubers">
      <div className="adaptive-container-box">
        {previewNftsCapped.map((nft, indx) => {
          return (
            <NftPreview key={indx} nft={nft} tooltip={tooltip} />
          )
        })}
      </div>
      { previewNftsCapped.length > 3 && // do not show "and many more..." on mobile
        <div className="flex justify-center align-center">
          <p className="and-many-more">and many more...</p>
        </div>
      }
    </div>
  )
}

const NftPreview = ({ nft, tooltip }) => {
  const handleNftPreviewClick = (ev) => {
    window.selectCollection(ev.currentTarget.dataset.slug);
  }
  const { logoUrl, name, slug } = nft;
  return (
    <div className="youtuber-avatar-container" data-slug={slug} onClick={handleNftPreviewClick}>
      <img className="youtuber-avatar" src={logoUrl} alt={name} />
      { tooltip === "on" &&
        <div className="tooltip-content">
          <h1 className="text-elipsis">{name}</h1>
        </div>
      }
    </div>
  );
}

export default Home;
