import EmailHelper from "./helper/EmailHelper";
import React, { useState, useEffect } from "react";

import { AppContext } from '../utils/AppContext'


const Overview = () => {
  const { ["nftfloorpriceOverview"]: [nftfloorpriceOverview] } = React.useContext(AppContext);

  let [nfts, setNfts] = useState([]);
  window.nfts = nfts;
  const [isLoading, setIsLoading] = useState(true);

  const query = new URLSearchParams(document.location.search);
  const slugs = query.get("slugs").split(",");

  useEffect(() => {
    async function loadNfts() {
      const result = await nftfloorpriceOverview({slugs: slugs});
      setNfts(result.data.data);
      setIsLoading(false);
    }
    loadNfts();
  }, []);

  // nfts = nfts.sort((a,b) => b.floorPrice - a.floorPrice);
  // const collectionsDict = getCollectionsDict();
  // if (collectionsDict) {
  //   nfts = nfts.filter(nftObj => collectionsDict[nftObj.slug]);
  // }

  return (
    <div className="nft-container">
      <h1>NFT Floor Prices</h1>
      <br/>
      {isLoading && (
        <div className="spinner">
          <div className="bounce1"></div>
          <div className="bounce2"></div>
          <div className="bounce3"></div>
        </div>
      )}
      {nfts.map(nftObj => (
        // OLD COMPONENTS (CARDS)
        // OLD COMPONENTS (CARDS)
        // OLD COMPONENTS (CARDS)
        // OLD COMPONENTS (CARDS)
        // <div key={nftObj.slug} className="nft-row">
        //   {/* <div className="text-elipsis">{nftObj.name}</div> */}
        //   <div className="title text-elipsis">{nftObj.slug}</div>
        //   <div>
        //     <a href={`https://opensea.io/collection/${nftObj.slug}`}><img className="avatar" src={nftObj.imageUrl} alt="" /></a>
        //   </div>
        //   <div className="price text-elipsis">{nftObj.stats.floorPrice} ETH</div>
        // </div>

        // NEW COMPONENTS => TABLE
        <div key={nftObj.slug} className="nft-row">
          <img className="avatar" src={nftObj.imageUrl}/>
          <div className="text-row">
            <p className="collection-name text-elipsis">{nftObj.name}</p>
            <p>{nftObj.stats.floorPrice}</p>
          </div>
        </div>

      ))}

      <br/>
      <br/>
      <br/>
      <p>Missing your favorite NFT collection?</p>
      <div onClick={EmailHelper.sendMailtoSubmitNFT} className="flex justify-center align-center">
        <div className="basic-bttn" style={{ marginTop: "18px"}}>
          Submit NFT Colelction
        </div>
      </div>
    </div>
  );
};

export default Overview;

/**
 * Helper function to extract collections
 * to track from the URL PARAMS
 */
function getCollectionsDict() {
  try {
    const urlParams = new URLSearchParams(window.location.search);
    const slugs = urlParams.get("filter").split(",");
    const collectionsDict = {};
    slugs.forEach(slug => collectionsDict[slug] = true);
    return collectionsDict;

  } catch(err) {
    return undefined;
  }
}
