import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Nav from "./Nav";
import Footer from "./Footer";
import BackgroundClippath from "./BackgroundClippath";
import Home from "./Home";
import Faq from "./Faq";
import Donate from "./Donate";
import Dashboard from "./Dashboard";
import AlertCreated from "./AlertCreated";
import AlertCreatedError from "./AlertCreatedError";
import AlertUnsubscribed from "./AlertUnsubscribed";
import Error404 from "./Error404";
import Overview from "./Overview";
import AddCollection from "./AddCollection";
import Terms from "./Terms";

const App = () => {

  return (
    <Router>
      <div className="flex-wrapper-for-footer">
        <div className="non-footer-content">
          <Nav />
          <BackgroundClippath />
          <div className="main-content">
            <Switch>
              <Route exact path="/">
                <Home/>
              </Route>
              <Route exact path="/addCollection">
                <AddCollection/>
              </Route>
              <Route exact path="/faq">
                <Faq/>
              </Route>
              <Route exact path="/overview">
                <Overview/>
              </Route>
              <Route exact path="/donate">
                <Donate/>
              </Route>
              <Route exact path="/dashboard/:token">
                <Dashboard />
              </Route>
              <Route exact path="/alertCreated">
                <AlertCreated/>
              </Route>
              <Route exact path="/alertCreatedError">
                <AlertCreatedError/>
              </Route>
              <Route exact path="/alertUnsubscribed">
                <AlertUnsubscribed/>
              </Route>
              <Route exact path="/alertOptIn">
                <AlertCreated/>
              </Route>
              <Route exact path="/404">
                <Error404/>
              </Route>
              <Route exact path="/terms">
                <Terms/>
              </Route>
              <Route path="*">
                <Error404/>
              </Route>
            </Switch>
          </div>
        </div>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
