import React, { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";


const BackgroundClippath = () => {
  // helper function
  // only show the background cluppath with
  // (1) on landing route + (2) viewport width > 1000
  const computeShowBackgroundClippath = () => {
    const isHomeRoute = window.location.pathname.toLowerCase().split("?")[0] === "/";
    const isWideEnough = window.innerWidth > 1000;
    return isHomeRoute && isWideEnough;
  }
  const [showBackgroundClippath, setShowBackgroundClippath] = useState(computeShowBackgroundClippath());
  const handleResizeEvent = () => {
    setShowBackgroundClippath(computeShowBackgroundClippath());
  }
  useEffect(() => {
    setShowBackgroundClippath(computeShowBackgroundClippath());
    window.addEventListener('resize', handleResizeEvent);
    return () => {
      window.removeEventListener('resize', handleResizeEvent);
    }
  }, [])

  const location = useLocation();
  useEffect(() => {
    setShowBackgroundClippath(computeShowBackgroundClippath());
  }, [location]);

  return (
    <React.Fragment>
      <div className={showBackgroundClippath ? "background-clippath-left" : ""}></div>
      <div className={showBackgroundClippath ? "background-clippath-right" : ""}></div>
    </React.Fragment>
  )
}

export default BackgroundClippath;
