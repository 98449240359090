import { Link } from "react-router-dom";

const AlertCreatedError = () => {
  return (
    <div>
      <div className="error404-container">
        <div className="error404-title alerts">
          ❌ Alert Creation Failed
        </div>
        <div className="error404-text">
          We could not create this alert, please try again or contact support at
        </div>
        <a className="email-reachout" href="mailto:hello@nftfloorprice.info" style={{marginBottom: "50px"}}>hello@nftfloorprice.info</a>
      </div>
      <div className="center-button">
        <div className="shillcoin-bttn">
          <Link to="/">Try Again</Link>
        </div>
      </div>
    </div>
  );
};

export default AlertCreatedError;
