import { Link } from "react-router-dom";

const Error404 = () => {
  return (
    <div className="error404-container">
      <div className="error404-title">
        <div className="glitch-wrapper">
          <div className="glitch" data-text="Page Not Found">Page Not Found</div>
        </div>
      </div>
      <div className="error404-title">
        <div className="glitch-wrapper glitch-wrapper-yellow">
          <div className="glitch" data-text="Error 404">Error 404</div>
        </div>
      </div>
      <div className="error404-text">
        We are sorry, something went wrong. Try again or reach out to
      </div>
      <a className="email-reachout" href="mailto:hello@nftfloorprice.info">hello@nftfloorprice.info</a>
      <br/>
      <br/>
      <br/>
      <div className="center-button">
        <div className="shillcoin-bttn">
          <Link to="/">Home</Link>
        </div>
      </div>
    </div>
  );
};

export default Error404;
