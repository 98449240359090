import React from "react";
import { useState, useRef, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../img/nftfloorprice-logo.svg";
import EmailHelper from "./helper/EmailHelper";
import { AppContext } from '../utils/AppContext'

const Nav = () => {
  // load global_state_variables (from AppContext store)
  const { ["isMobile"]: [isMobile, _] } = React.useContext(AppContext);

  // define local_state_variables
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(undefined);

  // GRANSPARENT NAVE CALCUIATIONS
  const computeTransparentNav = () => {
    const isHomeRoute = window.location.pathname.toLowerCase().split("?")[0] === "/";
    const isWideEnough = window.innerWidth > 1000;
    return isHomeRoute && isWideEnough;
  }
  const [transparentNav, setTransparentNav] = useState(computeTransparentNav());
  const handleResizeEvent = () => {
    setTransparentNav(computeTransparentNav());
  }
  useEffect(() => {
    setTransparentNav(computeTransparentNav());
    window.addEventListener('resize', handleResizeEvent);
    return () => {
      window.removeEventListener('resize', handleResizeEvent);
    }
  }, [])
  const location = useLocation();
  useEffect(() => {
    setTransparentNav(computeTransparentNav());
  }, [location]);


  const navLinks = useRef(null);
  const handleMenu = () => {
    if (!menuIsOpen) {
      navLinks.current.classList.add("show");
      document.querySelector(".hamburger").classList.add("is-active");
    } else {
      navLinks.current.classList.remove("show");
      document.querySelector(".hamburger").classList.remove("is-active");
    }
    setMenuIsOpen(!menuIsOpen);
  };

  // handle scroll event
  const handleScrollEvent = (event) => {
    if (window.scrollY > 100) {
      document
        .querySelector(".nav-wrapper-sticky")
        .classList.add("box-shadow-on-scroll");
    } else {
      document
        .querySelector(".nav-wrapper-sticky")
        .classList.remove("box-shadow-on-scroll");
    }
  }
  useEffect(() => {
    window.addEventListener("scroll", handleScrollEvent);
    return () => {
      window.removeEventListener("scroll", handleScrollEvent);
    }
  }, [])
  // add boxshadow on navbar when scrollposition is not 0

  const easterEgg = (ev) => {
    if (ev.detail === 3) { // easteregg
      window.location = "/overview";
    }
  }
  const setNavState = (ev) => {
    easterEgg(ev);
    const navbarState = ev.currentTarget.dataset.id || undefined;
    setSelectedItem(navbarState);
  }

  const gotToDiscord = () => {
    window.location = "https://discord.gg/E6xdECPdyp";
  }
  // template taken from basic example
  // https://stackoverflow.com/a/35165405/6272061
  const renderDesktop = () => {
    return (
      <nav className="desktop">
        <ul>
          <li onClick={setNavState}>
            <Link className="flex justify-center align-center" to="/" >
              <div className="nav-logo">
                <img src={logo} alt="NftFloorPrice logo" />
              </div>
              <div className="nav-title">nftfloorprice.info</div>
            </Link>
          </li>
          <li className={`${selectedItem === "faq" ? "scaled" : ""} scale-on-hover`} data-id="faq" onClick={setNavState}>
            <Link to="/faq">FAQ</Link>
          </li>
          <li className={`${selectedItem === "donate" ? "scaled" : ""} scale-on-hover`} data-id="donate" onClick={setNavState}>
            <Link to="/donate">Donate</Link>
          </li>
          <li className={`${selectedItem === "terms" ? "scaled" : ""} scale-on-hover`} data-id="terms" onClick={setNavState}>
            <Link to="/terms">Terms</Link>
          </li>
        </ul>
      </nav>
    );
  };
  const renderMobile = () => {
    return (
      <nav className="nav mobile">
        <div className="nav-menu">
          <div className="nav-info">
            <Link
              to="/"
              onClick={() => {
                if (menuIsOpen) handleMenu();
              }}
            >
              <div className="nav-logo">
                <img onClick={easterEgg} src={logo} alt="nft floor price logo" />
              </div>
              <div className="nav-title">nftfloorprice.info</div>
            </Link>
          </div>
          <button
            className="hamburger hamburger--squeeze no-select"
            type="button"
            onClick={handleMenu}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </button>
        </div>
        <div className="nav-links" ref={navLinks}>
          <Link to="/faq" onClick={handleMenu}>
            FAQ
          </Link>
          <Link to="/donate" onClick={handleMenu}>
            <div className="flex justify-center align-center">
              <p>Donate</p>
              <svg
                className="heart-svg"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 30 30"
              >
                <path d="M 9.5449219 4 C 5.9299219 4 3 6.9299219 3 10.544922 C 3 16.837321 10.298975 22.849799 13.708984 25.527344 A 2 2 0 0 0 13.71875 25.535156 C 13.742115 25.5535 13.773881 25.579629 13.796875 25.597656 L 13.798828 25.595703 A 2 2 0 0 0 15 26 A 2 2 0 0 0 16.203125 25.595703 L 16.203125 25.597656 C 16.209855 25.59238 16.219801 25.585381 16.226562 25.580078 C 16.231704 25.576045 16.23898 25.570455 16.244141 25.566406 A 2 2 0 0 0 16.263672 25.548828 C 19.663109 22.880904 27 16.852336 27 10.544922 C 27 6.9299219 24.070078 4 20.455078 4 C 17.000078 4 15 7 15 7 C 15 7 12.999922 4 9.5449219 4 z"></path>
              </svg>
            </div>
          </Link>
          <Link to="/terms" onClick={handleMenu}>
            Terms
          </Link>
          <button onClick={gotToDiscord}>Discord</button>
          <button onClick={EmailHelper.sendMailtoContact}>Contact</button>
        </div>
      </nav>
    );
  };

  return (
    <React.Fragment>
      <div className={`nav-wrapper-sticky ${transparentNav ? "transparent" : "non-transparent"}`}>
        {isMobile ? renderMobile() : renderDesktop()}
      </div>
      <div className="nav-height-placeholder"></div>
    </React.Fragment>
  );
};

export default Nav;
