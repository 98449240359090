import  React, { useState, useEffect, useRef } from "react";
import { Link } from 'react-router-dom';

import { FaSearch, FaInfoCircle} from "react-icons/fa";
import { SiEthereum } from "react-icons/si";
import { MdEmail, MdVerified } from "react-icons/md";
import { isEmptyObject } from "../helper/HelperFunctions.js";
import { AppContext } from '../../utils/AppContext';

const CreateAlertForm = () => {
  const { ["nftDict"]: [nftDict, setNftDict] } = React.useContext(AppContext);
  const { ["fetchFloorPrice"]: [fetchFloorPrice] } = React.useContext(AppContext);
  let nftArray = useRef([]);

  useEffect(() => {
    nftArray.current = Object.values(nftDict);
    nftArray.current.forEach(obj => {
      const target = obj.slug.toLowerCase() + " " + obj.name.toLowerCase();
      obj["searchable"] = target;
    });
    if (collectionInput.current.value !== "") {
      searchNftCollection(collectionInput.current.value);
    }

    const query = new URLSearchParams(document.location.search);
    const email = query.get("email");
    const slug = query.get("collection");
    if (email) {
      emailInput.current.value = email;
    }
    if (slug && nftDict[slug]) {
      selectCollection(slug);
    }
  }, [nftDict]);

  const [searchResults, setSearchResults] = useState([]);
  const [selected, setSelected] = useState({});
  const [selectedFloor, setSelectedFloor] = useState(undefined);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [targetFloor, setTargetFloor] = useState(undefined);
  const [query, setQuery] = useState("");
  const collectionInput = useRef(null);
  const emailInput = useRef(null);

  window.nftDict = nftDict;
  window.selected = selected;

  const handleKeyUpCollection = (ev) => {
    const searchTerm = ev.target.value.toLowerCase();
    setQuery(searchTerm);
    if (nftArray.current.length > 0) {
      searchNftCollection(searchTerm);
    }
  }
  const searchNftCollection = (searchTerm) => {
    if (searchTerm === "") {
      setSearchResults([]);
    } else if (searchTerm.length > 0 && searchTerm.length < 3) {
      setSearchResults(nftArray.current.filter(obj => obj.searchable.startsWith(searchTerm)));
    } else if (searchTerm.length >= 3) {
      setSearchResults(nftArray.current.filter(obj => obj.searchable.includes(searchTerm)));
    }
  }
  const handleFocus = (ev) => {
    resetSelection();
    ev.target.value = "";
    // setSearchResults([]);
    setQuery("");
  }
  const resetSelection = () => {
    setSelected({});
    setSelectedFloor(undefined);
    setQuery("");
  }

  const handleKeyUpTargetPrice = (ev) => {
    const value = ev.target.value;
    if (value.match(/^[0-9\.\,]*$/)) {
      const targetFloorPrice = (Number(value));
      setTargetFloor(Number(targetFloorPrice));
    } else {
      // error
      console.error("error with input: " + value);
      setTargetFloor(undefined);
    }
  }
  const selectCollection = async (slug) => {
    if (!nftDict[slug]) {
      return;
    }
    setSelectedFloor("loading");
    setSelected(nftDict[slug]);
    setSearchResults([]);
    collectionInput.current.value = nftDict[slug].name;
    const result = await fetchFloorPrice({slug: slug});
    if (result.data.error) {
      setSelectedFloor("error");
    }
    setSelectedFloor(Number(result.data.floorPrice));
  }
  window.selectCollection = selectCollection; // expose for the click function outside

  const submitForm = (ev) => {
    ev.preventDefault();
    // get inputs
    const slug = selected.slug;
    const currentFloor = selectedFloor;
    const email = emailInput.current.value.toLowerCase();
    // validate inputs
    if (typeof (slug) !== "string" || slug === "") {
      alert("🚧 Please select collection");
      return;
    }
    if (typeof targetFloor !== 'number') {
      alert("🚧 Please enter a valid target floor price to create your alert");
      return;
    }
    if (typeof currentFloor !== 'number') {
      alert("🚧 Please wait for the current floor price to be loaded");
      return;
    }
    if (!emailIsValid(email)) {
      alert("🚧 Invalid email address");
      return;
    }
    setFormSubmitted(true);
    let url = `https://us-central1-shillcoin.cloudfunctions.net/nftfloorpriceCreateNftAlert`;
    url += `?email=${email}`;
    url += `&slug=${slug}`;
    url += `&currentPrice=${currentFloor}`;
    url += `&targetPrice=${targetFloor}`;
    window.location.href = url;
  };

  const emailIsValid = (email) => {
    const regEx = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    return regEx.test(String(email).toLowerCase());
  };

  const selectedCollectionName = selected.name || "____";

  return (
    <div className="create-alert-form-container">
      <form autoComplete="off" onSubmit={submitForm}>
        <div className="pos-relative">
          <div className="vertical-line"></div>
          {selected.thumbnail === undefined
            ? <FaSearch size={20} className="input-icon-left"/>
            : <img src={selected.thumbnail} className="selected-nft-thumbnail"/>
          }

          <input ref={collectionInput} type="text" placeholder="Find NFT Collection..."
            onKeyUp={handleKeyUpCollection}
            onFocus={handleFocus}
            defaultValue={selected.name || ""}
          /><br/>
          <div className="results-container">
            {searchResults.map((searchResult, indx) => {
              return (
                <SearchResult
                  searchResult={searchResult}
                  setSelected={setSelected}
                  setSelectedFloor={setSelectedFloor}
                  setSearchResults={setSearchResults}
                  collectionInput={collectionInput}
                  key={indx}
                />
              )
            })}
            { isEmptyObject(nftDict) && query !== ""
              ? <LoadingSpinnerDots extraClass="add-extra-margin"/>
              : searchResults.length === 0 && query !== "" && Object.keys(selected).length === 0 &&
              <div className="no-results-subtitle">
                <p className="collection-missing-text">No results for '{query}'. <br /><br />Is your favorite NFT collection missing? Please help us by adding it 🙏</p>
                <Link to="/addCollection">
                  <div className="remove-all-link-styling add-missing-collection-bttn">Add Collection</div>
                </Link>
              </div>
            }
          </div>
        </div>
        <div className="pos-relative">
          <div className="vertical-line"></div>
          <SiEthereum size={20} className="input-icon-left"/>
          <input type="number" step="any" placeholder="Set Alert Price in ETH..." onKeyUp={handleKeyUpTargetPrice}/><br/>
          <LoadingText selectedFloor={selectedFloor}/>
        </div>
        <div className="pos-relative">
          <div className="vertical-line"></div>
          <MdEmail size={20} className="input-icon-left"/>
          <input ref={emailInput} type="text" className="email-input" placeholder="Enter Email..." autoComplete="new-password"/><br/>
        </div>
        <div className="alert-preview-text">
          {<FaInfoCircle color="lightblue" style={{marginRight: "5px"}}/>}
          we will send you an email<br/>
          when <span className={selected.name ? "highlighted" : ""}>{selectedCollectionName}</span> reaches<br/>
          a floor price of
          <span className={targetFloor ? "highlighted" : ""}>
            <TargetPrice targetFloor={targetFloor} selectedFloor={selectedFloor}/>
          </span>
        </div>
        <button className="submit-bttn create-alert-bttn" onClick={submitForm}>
          <p>Create Alert {!formSubmitted && "🔔"}</p>
          {formSubmitted && <LoadingSpinnerDots/>}
        </button>
      </form>
    </div>
  );
}


const TargetPrice = ({targetFloor, selectedFloor}) => {
  let selectedTargetPriceStr = "____";
  let color = "rgba(255, 255, 255, 0.8)";
  if (targetFloor && targetFloor >= selectedFloor) {
    color = "lightgreen";
    selectedTargetPriceStr = `above ${targetFloor}`;
  } else if (targetFloor && targetFloor < selectedFloor) {
    color = "coral";
    selectedTargetPriceStr = `below ${targetFloor}`;
  }
  return (
    <span style={{marginLeft: "4px", color: color}}>
      {selectedTargetPriceStr}
      <SiEthereum size={14} color={color}/>
      ETH
    </span>
  );
}

const LoadingText = ({selectedFloor}) => {
  return (
    <div className="flex justify-center loading-text-container">
      {selectedFloor === "loading"
        ? <p id="loading-text">fetching floor price</p>
        : selectedFloor === undefined
          ? <p id="loading-text"></p>
          : <p id="loading-text">current floor price: {selectedFloor}</p>
      }
      {selectedFloor === "loading" &&
        <LoadingDot />
      }
    </div>
  )
}

const LoadingDot = () => {
  return (
    <div className="spinner-tobiasahlin">
      <div className="double-bounce1"></div>
      <div className="double-bounce2"></div>
    </div>
  )
}

const LoadingSpinnerDots = ({extraClass}) => {
  extraClass = extraClass || "";
  return (
    <div className={`spinner-black ${extraClass}`}>
      <div className="bounce1"></div>
      <div className="bounce2"></div>
      <div className="bounce3"></div>
    </div>
  )
}

const SearchResult = ({searchResult, setSelected, setSearchResults, setSelectedFloor, collectionInput}) => {
  const { ["fetchFloorPrice"]: [fetchFloorPrice] } = React.useContext(AppContext);

  const slug = searchResult.slug;
  const name = searchResult.name;
  const thumbnail = searchResult.thumbnail;
  const isVerified = searchResult.isVerified;

  const selectCollection = async () => {
    setSelectedFloor("loading");
    setSelected(searchResult);
    setSearchResults([]);
    collectionInput.current.value = name;
    const result = await fetchFloorPrice({slug: slug});
    if (result.data.error) {
      setSelectedFloor("error");
    }
    setSelectedFloor(Number(result.data.floorPrice));
  }
  return (
    <div className="result-item" onClick={selectCollection}>
      <img className="collection-thumbnail" src={thumbnail} />
      {isVerified &&
        <MdVerified className="is-verified-badge" size={20} />
      }
      <p className="collection-name text-elipsis">{name}</p>
    </div>
  )
}

export default CreateAlertForm;
