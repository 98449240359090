import { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from '../utils/AppContext'
import { SiEthereum } from "react-icons/si";
import { Link } from "react-router-dom";

const Dashboard = () => {
  let { token } = useParams();
  const [pageStatus, setPageStatus] = useState("loading");
  const [userData, setUserData] = useState({});
  const [alerts, setAlerts] = useState([]);
  const { ["nftfloorpriceFetchDashboard"]: [nftfloorpriceFetchDashboard] } = useContext(AppContext);
  window.pageStatus = pageStatus;
  useEffect(() => {
    async function loadDashboardData() {
      const result = await nftfloorpriceFetchDashboard({token: token});
      if (result.data.status === "ok") {
        setPageStatus("ok");
        console.log(result.data);
        setUserData(result.data.userData);
        setAlerts(result.data.alerts.open);
      } else {
        setPageStatus("error");
      }
    }
    loadDashboardData();
  },[]);

  const handleUnsubscribe = () => {
    if (window.confirm('🚧 Are you sure? This will delete all your open alerts')) {
      console.log('unsubscribed!');
    } else {
      console.log('cancelled unsubscription');
    }
  }
  return (
    <div className="dashboard-container">
      <h1 className="title">Your Alerts 🔔</h1>
      {pageStatus === "loading" &&
        <div className="spinner">
          <div className="bounce1"></div>
          <div className="bounce2"></div>
          <div className="bounce3"></div>
        </div>
      }
      {alerts.map((alert, indx) => {
        const color = alert.notifyWhen === "above" ? "lightgreen" : "coral"
        return (
          <p className="alert-text" key={indx}>
            <span className="">{alert.slug}</span>: <span className="bold-text" style={{color: color}}>
              {alert.notifyWhen} {alert.targetPrice}
              <SiEthereum size={14} className="input-icon-left"/>
            </span>
            <br/>
          </p>
        )
      })}
      <div className="dashboard-new-alert-bttn center-button">
        <div className="shillcoin-bttn">
          <Link to={`/${userData.email ? `?email=${userData.email}` : ""}`}>New Alert</Link>
        </div>
      </div>
      <div className="center-button">
        <div onClick={handleUnsubscribe} className="dashboard-unsubscribe-bttn">
          <p>Unsubscribe</p>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
