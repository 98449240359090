import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { MdMail} from "react-icons/md";
import { FaDiscord, FaTwitter } from "react-icons/fa";

const Footer = () => {
  // helper function
  // footer has different display properties:
  // (1) credits (by dcts and 0xpixels)
  // (2) contact (icons for email and discord)
  // (3) background-color (transparent, color)
  // computeFooterState returns object:
  // {
  //   credits: true,
  //   contact: true,
  //   backgroundColor: "transparent"
  // }
  const computeFooterState = () => {
    const isNotHomeRoute = window.location.pathname.toLowerCase().split("?")[0] !== "/";
    // all pages except home page
    if (isNotHomeRoute) {
      return {
        credits: window.innerWidth > 330,
        contact: true,
        backgroundColor: "#17191d",
      };
    }
    // on home page
    return {
      credits: window.innerWidth > 330,
      contact: true,
      backgroundColor: window.innerWidth > 1000 ? "transparent" : "#17191d",
    }
  }
  const [footer, setFooter] = useState(computeFooterState());

  // on every change of URL, check if footer needs to be rendered
  const location = useLocation();
  useEffect(() => {
    setFooter(computeFooterState());
  }, [location]);
  // on every change of viewport width, check if footer needs to be rendered
  const handleResizeEvent = () => {
    setFooter(computeFooterState());
  }
  useEffect(() => {
    window.addEventListener('resize', handleResizeEvent);
    return () => {
      window.removeEventListener('resize', handleResizeEvent);
    }
  }, [])

  return (
    <div className="footer" style={{ backgroundColor: footer.backgroundColor }}>
      <div className="content flex align-center">
        { footer.credits &&
          <>
            by
            <a className="remove-all-link-styling personal-website-link" href="https://twitter.com/dcts_dev" target="_blank" rel="noreferrer">dcts</a> and
            <a className="remove-all-link-styling personal-website-link" href="https://twitter.com/0nlypixels" target="_blank" rel="noreferrer">0xpixels</a>
          </>
        }
        { footer.contact &&
          <>
            <a href="mailto:hello@nftfloorprice.info">
              <span className="contact-icon flex align-center">{<MdMail />}</span>
            </a>
            <a href="https://discord.gg/E6xdECPdyp" target="_blank" rel="noreferrer">
              <span className="contact-icon flex align-center">{<FaDiscord />}</span>
            </a>
            <a href="https://twitter.com/nftfloorprices" target="_blank" rel="noreferrer">
              <span className="contact-icon flex align-center">{<FaTwitter />}</span>
            </a>
          </>
        }
      </div>
    </div>
  );
};

export default Footer;
