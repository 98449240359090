import { useRef, useState, useContext } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { isEmptyObject } from "./helper/HelperFunctions.js";
import { MdVerified } from "react-icons/md";
import EmailHelper from "./helper/EmailHelper.js";

const AddCollection = () => {
  const openseaUrlInput = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorUrl, setErrorUrl] = useState("");
  const [addedCollection, setAddedCollection] = useState({});
  window.setIsError = setIsError;

  const validUrl = (url) => {
    const validFormat = url.includes("opensea.io/collection/");
    const containsSlug = !url.endsWith("opensea.io/collection/");
    return validFormat && containsSlug;
  }
  const extractSlug = (url) => {
    return url.split("opensea.io/collection/")[1].split("?")[0];
  }
  const handleAddCollection = async () => {
    const url = openseaUrlInput.current.value;
    if (!validUrl(url)) {
      alert("🚧 Ops, that did not work. Provided link needs to have the following format: opensea.io/collection/COLLECTION_ID");
      return;
    }
    setIsLoading(true);
    const slug = extractSlug(url);
    const response = await axios.get(`https://us-central1-shillcoin.cloudfunctions.net/nftfloorpriceAddCollection?slug=${slug}`);
    setIsLoading(false);
    if (response.data.status === "ok") {
      setAddedCollection(response.data.collectionData);
    } else {
      setIsError(true);
      setErrorUrl(url);
    }
  }


  const addAnotherCollection = () => {
    setIsLoading(false);
    setIsError(false);
    setErrorUrl("");
    setAddedCollection({});
  }

  const renderError = () => {
    return (
      <>
        <h1 className="title">🚧 Ooops, something went wrong</h1>
        <div className="center-button">
          <div className="main-bttn bttn-primary" onClick={addAnotherCollection}>
            Try Again
          </div>
        </div>
        <div className="center-button">
          <div className="main-bttn bttn-secondary" onClick={() => EmailHelper.sendMailCouldNotAddCollection(errorUrl)}>
            Contact Support
          </div>
        </div>
      </>
    )
  }

  const renderForm = () => {
    return (
      <>
        <h1 className="title">Add Collection</h1>
        <p className="inser-opensea-link-text">Insert Opensea Link</p>
        <input ref={openseaUrlInput} type="text" placeholder="e.g. https://opensea.io/collection/treeverse"></input>
        <button onClick={handleAddCollection} className="submit-bttn create-alert-bttn">
          <p>Add Collection</p>
          { isLoading &&
            <LoadingSpinnerDots />
          }
        </button>
      </>
    )
  }
  const renderLoadedCollection = () => {
    return (
      <>
        <h1 className="title">Success 🎉</h1>
        <div className="added-collection-card">
          <div className="image-container">
            <img className="added-collection-avatar" src={addedCollection.imageUrl} />
            { addedCollection.isVerified &&
              <div className="verified-icon">
                <MdVerified size={40}/>
              </div>
            }
          </div>
          <h2 className="collection-name">{addedCollection.name}</h2>
          <p>{addedCollection.floorPrice} ETH</p>
        </div>
        <div className="center-button">
          <div className="main-bttn bttn-primary" onClick={() => window.location.href = `/?collection=${addedCollection.slug}`}>
            Create Alert 🔔
          </div>
        </div>
        <div className="center-button">
          <div className="main-bttn bttn-secondary" onClick={addAnotherCollection}>
            Add another collection
          </div>
        </div>
      </>
    )
  }


  const LoadingSpinnerDots = ({extraClass}) => {
    extraClass = extraClass || "";
    return (
      <div className={`spinner-black ${extraClass}`}>
        <div className="bounce1"></div>
        <div className="bounce2"></div>
        <div className="bounce3"></div>
      </div>
    )
  }

  return (
    <div className="add-collection-container">
      { isEmptyObject(addedCollection) && !isError
        ? renderForm()
        : isError
          ? renderError()
          : renderLoadedCollection()
      }
    </div>
  );
};

export default AddCollection;
