import { Link } from "react-router-dom";

const AlertCreatedError = () => {
  return (
    <div>
      <div className="error404-container larger alerts">
        <div className="error404-title alerts">
          ❌ Unsubscribed from Alerts
        </div>
        <div className="error404-text larger-text">
          We will no longer send you alerts, for any feedback reach out to
        </div>
        <a className="email-reachout" href="mailto:hello@nftfloorprice.info" style={{marginBottom: "50px"}}>hello@nftfloorprice.info</a>
      </div>
      <div className="center-button">
        <div className="shillcoin-bttn">
          <Link to="/">Home</Link>
        </div>
      </div>
    </div>
  );
};

export default AlertCreatedError;
