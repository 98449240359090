import { useState, useEffect } from "react";
// import ReactMarkdown from 'react-markdown';
// import termsMD from "../components/Terms/terms.md";
// import privacyMD from "../components/Terms/privacy.md";

const Terms = () => {
  useEffect(() => {
    // async function fetchTerms() {
    //   fetch(termsMD).then((r) => r.text()).then(text  => {
    //     setTermsContent(text);
    //   });
    // };
    // async function fetchPrivacy() {
    //   fetch(privacyMD).then((r) => r.text()).then(text  => {
    //     setPrivacyContent(text);
    //   });
    // };
    // fetchTerms();
    // fetchPrivacy();
  }, []);

  const [currentSectionId, setCurrentSectionId] = useState("terms");
  // const [privacyContent, setPrivacyContent] = useState("loading...");
  // const [termsContent, setTermsContent] = useState("loading...");

  const sections = [
    {
      id: "terms",
      name: "Terms & Conditions",
      description: "Please read the following terms & privacy policy carefully. NFTfloorprices is for informational purposes only and does not constitute investment advice. As always, do your own research.",
    },
    {
      id: "privacy",
      name: "Privacy Policy",
      description: "Please read the following terms & privacy policy carefully. NFTfloorprices is for informational purposes only and does not constitute investment advice. As always, do your own research.",
    },
    {
      id: "impressum",
      name: "Impressum",
      description: "Please read the following terms & privacy policy carefully. NFTfloorprices is for informational purposes only and does not constitute investment advice. As always, do your own research.",
    }
  ]

  const handleClick = (ev) => {
    const targetSection = sections.find(el => el.id === ev.target.textContent.toLowerCase());
    if (targetSection){
      setCurrentSectionId(targetSection.id)
    }
  }

  const currentSection = sections.find(el => el.id === currentSectionId) || sections[0];

  return (
    <div className="terms" style={{marginBottom: "40px"}}>
      <section className="terms-header">
        <div className="inner-wrapper-hero">
          <div className="terms-intro">
            <h4>{currentSection.name}</h4>
            <p id="info">{currentSection.description}</p>
          </div>
        </div>
      </section>
      <div className="legal-navigation">
        <div className="terms-navigation-line"></div>
        <div className="terms-navigation">
          <p className={`terms-navigation-item ${currentSectionId === "terms" ? "selected" : ""}`} onClick={handleClick}>Terms</p>
          <p className={`terms-navigation-item ${currentSectionId === "privacy" ? "selected" : ""}`} onClick={handleClick}>Privacy</p>
          <p className={`terms-navigation-item ${currentSectionId === "impressum" ? "selected" : ""}`} onClick={handleClick}>Impressum</p>
        </div>
      </div>

      { currentSectionId === "terms"
        ? <TermsContent />
        // ? <ReactMarkdown>{termsContent}</ReactMarkdown>
        : currentSectionId === "privacy"
          // ? <ReactMarkdown>{privacyContent}</ReactMarkdown>
          ? <PrivacyContent />
          : currentSectionId === "impressum"
          // ? <ReactMarkdown>{termsContent}</ReactMarkdown>
            ? <ImpressumContent />
            : (<h1>Error</h1>)
      }
    </div>
  );
};


const PrivacyContent = () => {
  return (
    <div className="legal-content">
      <div className="terms-intro">
        <p>This Website collects some Personal Data from its Users.</p>
        <p style={{marginBottom:"25px"}}>This document can be printed for reference by using the print command in the settings of any browser.</p>
      </div>
      {/* <div className="terms-main-section">
        <h3>Owner and Data Controller</h3>
        <br></br>
        <p className="terms-inline-bold" style={{paddingLeft:"18px"}}>Remotehunt UG (haftungsbeschränkt)</p>
        <p className="terms-inline-nomargin" style={{paddingLeft:"18px"}}>c/o Spielfeld Digital Hub</p>
        <p className="terms-inline-nomargin" style={{paddingLeft:"18px"}}>Skalitzerstraße 85/86</p>
        <p style={{paddingLeft:"18px"}}>10997 Berlin</p>
        <p style={{paddingLeft:"18px"}}><span className="terms-inline-bold">E-Mail-Adresse des Anbieters:</span> <a href="mailto:hello@nftfloorprice.info" style={{textDecoration:"none",color:"#666666"}}>hello@nftfloorprice.info</a></p>
      </div> */}
      <div className="terms-main-section">
        <h2 id="types_of_data">
          Types of Data collected
        </h2>
          <p>
            Among the types of Personal Data that this Website collects, by itself or through third parties, there are:
            Usage Data; device information; geography/region; number of Users ; number of sessions; session duration; Application opens; Application updates; first launches; operating systems; email address; first name; last name.
          </p>
        <p>Complete details on each type of Personal Data collected are provided in the dedicated sections of this privacy policy or by specific explanation texts displayed prior to the Data collection.<br />Personal Data may be freely provided by the User, or, in case of Usage Data, collected automatically when using this Website.<br />Unless specified otherwise, all Data requested by this Website is mandatory and failure to provide this Data may make it impossible for this Website to provide its services. In cases where this Website specifically states that some Data is not mandatory, Users are free not to communicate this Data without consequences to the availability or the functioning of the Service.<br />Users who are uncertain about which Personal Data is mandatory are welcome to contact the Owner.<br />Any use of Cookies – or of other tracking tools – by this Website or by the owners of third-party services used by this Website serves the purpose of providing the Service required by the User, in addition to any other purposes described in the present document and in the Cookie Policy, if available.</p>
        <p>Users are responsible for any third-party Personal Data obtained, published or shared through this Website and confirm that they have the third party's consent to provide the Data to the Owner.</p>
      </div>
      <div className="terms-main-section">
        <h2 id="place_of_processing">Mode and place of processing the Data</h2>
        <h3>Methods of processing</h3>
        <p>The Owner takes appropriate security measures to prevent unauthorized access, disclosure, modification, or unauthorized destruction of the Data.<br />The Data processing is carried out using computers and/or IT enabled tools, following organizational procedures and modes strictly related to the purposes indicated. In addition to the Owner, in some cases, the Data may be accessible to certain types of persons in charge, involved with the operation of this Website (administration, sales, marketing, legal, system administration) or external parties (such as third-party technical service providers, mail carriers, hosting providers, IT companies, communications agencies) appointed, if necessary, as Data Processors by the Owner. The updated list of these parties may be requested from the Owner at any time.</p>
        <h3>Legal basis of processing</h3>
        <p>The Owner may process Personal Data relating to Users if one of the following applies:</p>
        <ul>
          <li>Users have given their consent for one or more specific purposes. Note: Under some legislations the Owner may be allowed to process Personal Data until the User objects to such processing (“opt-out”), without having to rely on consent or any other of the following legal bases. This, however, does not apply, whenever the processing of Personal Data is subject to European data protection law;</li>
          <li>provision of Data is necessary for the performance of an agreement with the User and/or for any pre-contractual obligations thereof;</li>
          <li>processing is necessary for compliance with a legal obligation to which the Owner is subject;</li>
          <li>processing is related to a task that is carried out in the public interest or in the exercise of official authority vested in the Owner;</li>
          <li>processing is necessary for the purposes of the legitimate interests pursued by the Owner or by a third party.</li>
        </ul>
        <p>In any case, the Owner will gladly help to clarify the specific legal basis that applies to the processing, and in particular whether the provision of Personal Data is a statutory or contractual requirement, or a requirement necessary to enter into a contract. </p>
        <h3>Place</h3>
        <p>The Data is processed at the Owner's operating offices and in any other places where the parties involved in the processing are located.<br /><br />
      Depending on the User's location, data transfers may involve transferring the User's Data to a country other than their own. To find out more about the place of processing of such transferred Data, Users can check the section containing details about the processing of Personal Data.</p>
        <p>Users are also entitled to learn about the legal basis of Data transfers to a country outside the European Union or to any international organization governed by public international law or set up by two or more countries, such as the UN, and about the security measures taken by the Owner to safeguard their Data.<br /><br />
      If any such transfer takes place, Users can find out more by checking the relevant sections of this document or inquire with the Owner using the information provided in the contact section.</p>
        <h3>Retention time</h3>
        <p>Personal Data shall be processed and stored for as long as required by the purpose they have been collected for.</p>
        <p>Therefore:</p>
        <ul>
          <li>Personal Data collected for purposes related to the performance of a contract between the Owner and the User shall be retained until such contract has been fully performed.</li>
          <li>Personal Data collected for the purposes of the Owner’s legitimate interests shall be retained as long as needed to fulfill such purposes. Users may find specific information regarding the legitimate interests pursued by the Owner within the relevant sections of  this document or by contacting the Owner.</li>
        </ul>
        <p>The Owner may be allowed to retain Personal Data for a longer period whenever the User has given consent to such processing, as long as such consent is not withdrawn. Furthermore, the Owner may be obliged to retain Personal Data for a longer period whenever required to do so for the performance of a legal obligation or upon order of an authority.<br /><br />
      Once the retention period expires, Personal Data shall be deleted. Therefore, the right of access, the right to erasure, the right to rectification and the right to data portability cannot be enforced after expiration of the retention period.</p>
      </div>
      <div className="terms-main-section">
        <h2 id="use_collected_data">The purposes of processing</h2>
        <p>
          The Data concerning the User is collected to allow the Owner to provide its Service, comply with its legal obligations, respond to enforcement requests, protect its rights and interests (or those of its Users or third parties), detect any malicious or fraudulent activity, as well as the following:
          Analytics, Displaying content from external platforms, Hosting and backend infrastructure, Infrastructure monitoring, Managing contacts and sending messages and Contacting the User.
        </p>
        <p>For specific information about the Personal Data used for each purpose, the User may refer to the section “Detailed information on the processing of Personal Data”.</p>
      </div>

      <div className="terms-main-section">
        <h2 id="data_processing_detailed_info">Detailed information on the processing of Personal Data</h2>
        <p>Personal Data is collected for the following purposes and using the following services:</p>
        <ul>
          <li>
            <h4>Analytics:</h4><br></br><p>The services contained in this section enable the Owner to monitor and analyze web traffic and can be used to keep track of User behavior.</p>
            <h5>Google Analytics for Firebase (Google LLC)</h5>
            <div className="wrap">
              <p>Google Analytics for Firebase or Firebase Analytics is an analytics service provided by Google LLC.<br/>
              <strong>In order to understand Google's use of Data, consult <a href="https://www.google.com/policies/privacy/partners/">Google's partner policy</a></strong>.</p>
              <p>Firebase Analytics may share Data with other tools provided by Firebase, such as Crash Reporting, Authentication, Remote Config or Notifications. The User may check this privacy policy to find a detailed explanation about the other tools used by the Owner.</p>
              <p>This Website uses identifiers for mobile devices and technologies similar to cookies to run the Firebase Analytics service.</p>
              <p>Users may opt-out of certain Firebase features through applicable device settings, such as the device advertising settings for mobile phones or by following the instructions in other Firebase related sections of this privacy policy, if available.</p>
            </div>
            <p>Personal Data processed: Application opens; Application updates; device information; first launches; geography/region; number of sessions; number of Users ; operating systems; session duration; Usage Data.</p>
            <p>Place of processing:  United States &ndash;  <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>. </p>
          </li>


          <li>
            <h4>Contacting the user:</h4><br></br>
            <div className="wrap">
              <p>By filling in the contact form with their Data, the User authorizes this Website to use these details to reply to requests for information, quotes or any other kind of request as indicated by the form’s header.</p>
            </div>
            <p>Personal Data processed: email address.</p>
          </li>

          <li>
            <h4>Managing contacts and sending messages:</h4><br></br>
            <p>This type of service makes it possible to manage a database of email contacts, phone contacts or any other contact information to communicate with the User.<br/>
  These services may also collect data concerning the date and time when the message was viewed by the User, as well as when the User interacted with it, such as by clicking on links included in the message.</p>
            <h5>Postmark (Wildbit LLC)</h5>
            <div className="wrap">
              <p>Postmark is an email address management and message sending service provided by Wildbit LLC.</p>
            </div>
            <p>Personal Data processed: email address; first name; last name.</p>
            <p>Place of processing:  United States &ndash;  <a href="https://wildbit.com/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>. </p>
          </li>

          <li>
            <h4>Displaying content from external platforms:</h4><br></br>
            <p>This type of service allows you to view content hosted on external platforms directly from the pages of this Website and interact with them.<br/>
  This type of service might still collect web traffic data for the pages where the service is installed, even when Users do not use it.</p>
              <h5>Google Fonts (Google LLC)</h5>
              <div className="wrap">
                <p>Google Fonts is a typeface visualization service provided by Google LLC that allows this Website to incorporate content of this kind on its pages.</p>
              </div>
              <p>Personal Data processed: Usage Data; various types of Data as specified in the privacy policy of the service.</p>
              <p>Place of processing:  United States &ndash;  <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.</p>
          </li>
          <li>
            <h4>Infrastructure monitoring:</h4><br></br>
            <p>This type of service allows this Website to monitor the use and behavior of its components so its performance, operation, maintenance and troubleshooting can be improved.<br/>
  Which Personal Data are processed depends on the characteristics and mode of implementation of these services, whose function is to filter the activities of this Website.</p>
            <h5>Firebase Performance Monitoring (Google LLC)</h5>
            <div className="wrap">
              <p>Firebase Performance Monitoring is a monitoring service provided by Google LLC.</p>
            </div>
            <p>Personal Data processed: various types of Data as specified in the privacy policy of the service.</p>
            <p>Place of processing:  United States &ndash;  <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.</p>
          </li>
          <li>
            <h4>Hosting and backend infrastructure:</h4><br></br>
            <p>This type of service has the purpose of hosting Data and files that enable this Website to run and be distributed as well as to provide a ready-made infrastructure to run specific features or parts of this Website.</p>
            <p>Some services among those listed below, if any, may work through geographically distributed servers, making it difficult to determine the actual location where the Personal Data are stored.</p>
            <h5>Firebase Cloud Firestore (Google LLC)</h5>
            <div className="wrap">
              <p>Firebase Cloud Firestore is a hosting and backend service provided by Google LLC.</p>
            </div>
            <p>Personal Data processed: Usage Data; various types of Data as specified in the privacy policy of the service.</p>
            <p>Place of processing:  United States &ndash;  <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>. </p>
            <h5>Firebase Cloud Functions (Google LLC)</h5>
            <div className="wrap">
              <p>Firebase Cloud Functions is a hosting and backend service provided by Google LLC.</p>
            </div>
            <p>Personal Data processed: Usage Data; various types of Data as specified in the privacy policy of the service.</p>
            <p>Place of processing:  United States &ndash;  <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>. </p>
            <h5>Firebase Hosting (Google LLC)</h5>
            <div className="wrap">
              <p>Firebase Hosting is a hosting service provided by Google LLC.</p>
            </div>
            <p>Personal Data processed: various types of Data as specified in the privacy policy of the service.</p>
            <p>Place of processing:  United States &ndash;  <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>. </p>
          </li>
        </ul>
      </div>
      <div className="terms-main-section">
        <h2 id="rights_subjects">The rights of Users</h2>
        <p>Users may exercise certain rights regarding their Data processed by the Owner.</p>
        <p>In particular, Users have the right to do the following:</p>

        <ul>
          <li><strong>Withdraw their consent at any time.</strong> Users have the right to withdraw consent where they have previously given their consent to the processing of their Personal Data.</li>
          <li><strong>Object to processing of their Data.</strong> Users have the right to object to the processing of their Data if the processing is carried out on a legal basis other than consent. Further details are provided in the dedicated section below.</li>
          <li><strong>Access their Data.</strong> Users have the right to learn if Data is being processed by the Owner, obtain disclosure regarding certain aspects of the processing and obtain a copy of the Data undergoing processing.</li>
          <li><strong>Verify and seek rectification.</strong> Users have the right to verify the accuracy of their Data and ask for it to be updated or corrected.</li>
          <li><strong>Restrict the processing of their Data.</strong> Users have the right, under certain circumstances, to restrict the processing of their Data. In this case, the Owner will not process their Data for any purpose other than storing it.
      </li>
          <li><strong>Have their Personal Data deleted or otherwise removed.</strong> Users have the right, under certain circumstances, to obtain the erasure of their Data from the Owner.</li>
          <li><strong>Receive their Data and have it transferred to another controller.</strong> Users have the right to receive their Data in a structured, commonly used and machine readable format and, if technically feasible, to have it transmitted to another controller without any hindrance. This provision is applicable provided that the Data is processed by automated means and that the processing is based on the User's consent, on a contract which the User is part of or on pre-contractual obligations thereof.</li>
          <li><strong>Lodge a complaint.</strong> Users have the right to bring a claim before their competent data protection authority.</li>
        </ul>

        <h3>Details about the right to object to processing</h3>
        <p>Where Personal Data is processed for a public interest, in the exercise of an official authority vested in the Owner or for the purposes of the legitimate interests pursued by the Owner, Users may object to such processing by providing a ground related to their particular situation to justify the objection.</p>
        <p>Users must know that, however, should their Personal Data be processed for direct marketing purposes, they can object to that processing at any time without providing any justification. To learn, whether the Owner is processing Personal Data for direct marketing purposes, Users may refer to the relevant sections of this document. </p>

        <h3>How to exercise these rights</h3>
        <p>Any requests to exercise User rights can be directed to the Owner through the contact details provided in this document. These requests can be exercised free of charge and will be addressed by the Owner as early as possible and always within one month.</p>
      </div>
      <div className="terms-main-section">
        <h2 id="further_data_processing_info">Additional information about Data collection and processing</h2>
          <h3>Legal action</h3>
          <p>The User's Personal Data may be used for legal purposes by the Owner in Court or in the stages leading to possible legal action arising from improper use of this Website or the related Services.<br />The User declares to be aware that the Owner may be required to reveal personal data upon request of public authorities.</p>
          <h3>Additional information about User's Personal Data</h3>
          <p>In addition to the information contained in this privacy policy, this Website may provide the User with additional and contextual information concerning particular Services or the collection and processing of Personal Data upon request.</p>
          <h3>System logs and maintenance</h3>
          <p>For operation and maintenance purposes, this Website and any third-party services may collect files that record interaction with this Website (System logs) use other Personal Data (such as the IP Address) for this purpose.</p>
          <h3>Information not contained in this policy</h3>
          <p>More details concerning the collection or processing of Personal Data may be requested from the Owner at any time. Please see the contact information at the beginning of this document.</p>
            <h3>How “Do Not Track” requests are handled</h3>
            <p>This Website does not support “Do Not Track” requests.<br />To determine whether any of the third-party services it uses honor the “Do Not Track” requests, please read their privacy policies.</p>
          <h3>Changes to this privacy policy</h3>
          <p>The Owner reserves the right to make changes to this privacy policy at any time by notifying its Users on this page and possibly within this Website and/or - as far as technically and legally feasible - sending a notice to Users via any contact information available to the Owner. It is strongly recommended to check this page often, referring to the date of the last modification listed at the bottom. <br /><br />
        Should the changes affect processing activities performed on the basis of the User’s consent, the Owner shall collect new consent from the User, where required.</p>
      </div>
    </div>
  )
}

const ImpressumContent = () => {
  return (
    <div className="legal-content">
      <div className="terms-intro">
        <div className="impressum">
          <div className="max-width-impressum">
            <section className="impressum-content">
              <div className="impressum-split">
                <div className="split">
                  {/* <div className="impressum-content-block">
                    <h3>Firma & Anschrift</h3>
                    <p className="info">Remotehunt UG (haftungsbeschränkt)</p>
                    <p className="info">c/o Spielfeld Digital Hub</p>
                    <p className="info">Skalitzerstraße 85/86</p>
                    <p className="info">10997 Berlin</p>
                  </div> */}
                  <div className="impressum-content-block">
                    <h3>Kontakt</h3>
                    <p><span className="inline-bold">E-Mail:</span>hello@nftfloorprice.info</p>
                  </div>
                </div>
                {/* <div className="split">
                  <div className="impressum-content-block">
                    <h3>Geschäftsführer</h3>
                    <p>David Friedl & Thomas Starzynski</p>
                  </div>
                  <div className="impressum-content-block">
                    <h3>Registergericht</h3>
                    <p>Amtsgericht Berlin-Charlottenburg</p>
                  </div>
                  <div className="impressum-content-block">
                    <h3>Registernummer</h3>
                    <p>HRB 224772 B</p>
                  </div>
                  <div className="impressum-content-block">
                    <h3>USt.-ID</h3>
                    <p><i>noch nicht vorhanden</i></p>
                  </div>
                </div> */}
              </div>
              <div className="impressum-content-block">
                <h3>Haftungshinweis:</h3>
                <p>Die Informationen, die auf diesen Seiten abgerufen werden können, erheben weder einen Anspruch auf Richtigkeit noch auf Vollständigkeit. Die Benutzung erfolgt auf eigene Gefahr.</p>
                <p>Wir weisen ausdrücklich darauf hin, dass wir für die Angebote Dritter im World Wide Web (sog “deep links”) nicht verantwortlich sind. Soweit Links auf die Angebote Dritter verweisen, enthalten diese nach unserer Kenntnis keine strafbaren oder anderweitig verbotenen Inhalte. Für diese Inhalte wird keinerlei Haftung übernommen.</p>
              </div>
              {/* <div className="impressum-content-block">
                <h3>Urheberrechtlicher Hinweis:</h3>
                <p>Inhalt und Struktur der Internetseiten von remotehunt.de sind urheberrechtlich geschützt. Jegliche Vervielfältigung von Informationen oder Daten, insbesondere die Verwendung von Texten, Textteilen, Bildmaterial oder sonstigen Inhalten bedarf der vorherigen Zustimmung durch den Anbieter bzw. Rechteinhaber.</p>
              </div> */}
              <div className="impressum-content-block">
                <h3>Hinweis zur Online-Streitbeilegung gemäß Art. 14 Abs. 1 ODR-VO:</h3>
                <p>Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, die Sie unter Online-Streitbeilegung finden. Verbraucher haben die Möglichkeit, diese Plattform für die Beilegung ihrer Streitigkeiten zu nutzen. Wir sind grundsätzlich bereit, an einem außergerichtlichen Schlichtungsverfahren teilzunehmen.</p>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  )
}

const TermsContent = () => {
  return (
    <div className="legal-content">
      <div className="terms-intro">
        <p>These Terms govern</p>
        <ul>
          <li>the use of this Website, and,</li>
          <li>any other related Agreement or legal relationship with the Owner
in a legally binding way. Capitalized words are defined in the relevant dedicated section of this document.</li>
        </ul>
        <p style={{marginBottom: "25px"}}>The User must read this document carefully.</p>
      </div>
      {/* <div className="terms-main-section">
        <p>This Website is provided by:</p>
        <p className="terms-inline-bold" style={{paddingLeft:"18px"}}>Remotehunt UG (haftungsbeschränkt)</p>
        <p className="terms-inline-nomargin" style={{paddingLeft:"18px"}}>c/o Spielfeld Digital Hub</p>
        <p className="terms-inline-nomargin" style={{paddingLeft:"18px"}}>Skalitzerstraße 85/86</p>
        <p style={{paddingLeft:"18px"}}>10997 Berlin</p>
        <p style={{paddingLeft:"18px"}}><span className="terms-inline-bold">E-Mail address:</span> <a href="mailto:hello@nftfloorprice.info" style={{textDecoration:"none",color:"#666666"}}>hello@nftfloorprice.info</a></p>
        <br/>
        <br/>
        <br/>
        <p>"This Website" refers to</p>
        <ul>
          <li>this website, including its subdomains and any other website through which the Owner makes its Service available;</li>
          <li>the Service;</li>
          <li>any applications, sample and content files, source code, scripts, instruction sets or software included as part of the Service, as well as any related documentation;</li>
        </ul>
      </div> */}

      <div className="terms-main-section">
        <h3>Terms Of Use</h3>
        <br></br>
        <p>Unless otherwise specified, the terms of use detailed in this section apply generally when using this Website.<br></br><br></br>Single or additional conditions of use or access may apply in specific scenarios and in such cases are additionally indicated within this document.<br></br><br></br>By using this Website, Users confirm to meet the following requirements:</p>
        <ul>
          <li>Users must qualify as Consumers;</li>
        </ul>
        <h3 id="content-on-this_application">Content on this Website</h3>
        <p>Unless where otherwise specified or clearly recognizable, all content available on this Website is owned or provided by the Owner or its licensors.</p>

        <p>The Owner undertakes its utmost effort to ensure that the content provided on this Website infringes no applicable legal provisions or third-party rights. However, it may not always be possible to achieve such a result.<br></br>
        In such cases, without prejudice to any legal prerogatives of Users to enforce their rights, Users are kindly asked to preferably report related complaints using the contact details provided in this document.</p>
        <h4 id="rights-regarding-content-on-this_application-all-rights-reserved">Rights regarding content on this Website - All rights reserved</h4>
        <p>The Owner holds and reserves all intellectual property rights for any such content.</p>

        <p>Users may not therefore use such content in any way that is not necessary or implicit in the proper use of the Service.</p>
        <p>In particular, but without limitation, Users may not copy, download, share (beyond the limits set forth below), modify, translate, transform, publish, transmit, sell, sublicense, edit, transfer/assign to third parties or create derivative works from the content available on this Website, nor allow any third party to do so through the User or their device, even without the User's knowledge.</p>

        <p>Where explicitly stated on this Website, the User may download, copy and/or share some content available through this Website for its sole personal and non-commercial use and provided that the copyright attributions and all the other attributions requested by the Owner are correctly implemented.</p>

        <p>Any applicable statutory limitation or exception to copyright shall stay unaffected.</p>
        <h3 id="access-to-external-resources">Access to external resources</h3>
        <p>Through this Website Users may have access to external resources provided by third parties. Users acknowledge and accept that the Owner has no control over such resources and is therefore not responsible for their content and availability.</p>

        <p>Conditions applicable to any resources provided by third parties, including those applicable to any possible grant of rights in content, result from each such third parties’ terms and conditions or, in the absence of those, applicable statutory law.</p>
        <h3 id="acceptable-use">Acceptable use</h3>
        <p>This Website and the Service may only be used within the scope of what they are provided for, under these Terms and applicable law.</p>

        <p>Users are solely responsible for making sure that their use of this Website and/or the Service violates no applicable law, regulations or third-party rights.</p>
        <p>Therefore, the Owner reserves the right to take any appropriate measure to protect its legitimate interests including by denying Users access to this Website or the Service, terminating contracts, reporting any misconduct performed through this Website or the Service to the competent authorities – such as judicial or administrative authorities - whenever Users engage or are suspected to engage in any of the following activities:</p>

        <ul>
        <li>violate laws, regulations and/or these Terms;</li>
        <li>infringe any third-party rights;</li>
        <li>considerably impair the Owner’s legitimate interests;</li>
        <li>offend the Owner or any third party.</li>
        </ul>
      </div>

      <div className="terms-main-section">
        <h2 id="liability-and-indemnification">Liability and indemnification</h2><h3 id="eu-users">EU Users</h3><h4 id="indemnification">Indemnification</h4>
        <p>The User agrees to indemnify and hold the Owner and its subsidiaries, affiliates, officers, directors, agents, co-branders, partners and employees harmless from and against any claim or demand ⁠— including but not limited to lawyer's fees and costs ⁠— made by any third party due to or in relation with any culpable use of or connection to the Service, violation of these Terms, infringement of any third-party rights or statutory provision by the User or its affiliates, officers, directors, agents, co-branders, partners and employees to the extent allowed by applicable law.</p>
        <h4 id="limitation-of-liability">Limitation of liability</h4>
        <p>Unless otherwise explicitly stated and without prejudice to applicable statutory product liability provisions, Users shall have no right to claim damages against the Owner (or any natural or legal person acting on its behalf).</p>
        <p>This does not apply to damages to life, health or physical integrity, damages resulting from the breach of an essential contractual obligation such as any obligation strictly necessary to achieve the purpose of the contract, and/or damages resulting from intent or gross negligence, as long as this Website has been appropriately and correctly used by the User.</p>
        <p>Unless damages have been caused by way of intent or gross negligence, or they affect life, health or physical integrity, the Owner shall only be liable to the extent of typical and foreseeable damages at the moment the contract was entered into.</p>
      </div>

      <div className="terms-main-section">
        <h2 id="common-provisions">Common provisions</h2><h3 id="no-waiver">No Waiver</h3>
        <p>The Owner’s failure to assert any right or provision under these Terms shall not constitute a waiver of any such right or provision.  No waiver shall be considered a further or continuing waiver of such term or any other term.</p>
        <h3 id="service-interruption">Service interruption</h3>
        <p>To ensure the best possible service level, the Owner reserves the right to interrupt the Service for maintenance, system updates or any other changes, informing the Users appropriately.</p>

        <p>Within the limits of law, the Owner may also decide to suspend or terminate the Service altogether. If the Service is terminated, the Owner will cooperate with Users to enable them to withdraw Personal Data or information in accordance with applicable law.</p>

        <p>Additionally, the Service might not be available due to reasons outside the Owner’s reasonable control, such as “force majeure” (eg. labor actions, infrastructural breakdowns or blackouts etc).</p>
        <h3 id="service-reselling">Service reselling</h3>
        <p>Users may not reproduce, duplicate, copy, sell, resell or exploit any portion of this Website and of its Service without the Owner’s express prior written permission, granted either directly or through a legitimate reselling programme.</p>
        <h3 id="privacy-policy">Privacy policy</h3>
        <p>To learn more about the use of their Personal Data, Users may refer to the privacy policy of this Website.</p>
        <h3 id="intellectual-property-rights">Intellectual property rights</h3>
        <p>Without prejudice to any more specific provision of these Terms, any intellectual property rights, such as copyrights, trademark rights, patent rights and design rights related to this Website are the exclusive property of the Owner or its licensors and are subject to the protection granted by applicable laws or international treaties relating to intellectual property.</p>

        <p>All trademarks — nominal or figurative — and all other marks, trade names, service marks, word marks, illustrations, images, or logos appearing in connection with this Website are, and remain, the exclusive property of the Owner or its licensors and are subject to the protection granted by applicable laws or international treaties related to intellectual property.</p>
        <h3 id="changes-to-these-terms">Changes to these Terms</h3>
        <p>The Owner reserves the right to amend or otherwise modify these Terms at any time. In such cases, the Owner will appropriately inform the User of these changes.</p>

        <p>Such changes will only affect the relationship with the User for the future.</p>

        <p>The continued use of the Service will signify the User’s acceptance of the revised Terms. If Users do not wish to be bound by the changes, they must stop using the Service. Failure to accept the revised Terms, may entitle either party to terminate the Agreement.</p>

        <p>The applicable previous version will govern the relationship prior to the User's acceptance. The User can obtain any previous version from the Owner.</p>
        <h3 id="assignment-of-contract">Assignment of contract</h3>
        <p>The Owner reserves the right to transfer, assign, dispose of by novation, or subcontract any or all rights or obligations under these Terms, taking the User’s legitimate interests into account.
        Provisions regarding changes of these Terms will apply accordingly.</p>

        <p>Users may not assign or transfer their rights or obligations under these Terms in any way, without the written permission of the Owner.</p>
        <h3 id="contacts">Contacts</h3>
        <p>All communications relating to the use of this Website must be sent using the contact information stated in this document.</p>
        <h3 id="severability">Severability</h3>
        <p>Should any provision of these Terms be deemed or become invalid or unenforceable under applicable law, the invalidity or unenforceability of such provision shall not affect the validity of the remaining provisions, which shall remain in full force and effect.</p>
        <h4 id="eu-users">EU Users</h4>
        <p>Should any provision of these Terms be or be deemed void, invalid or unenforceable, the parties shall do their best to find, in an amicable way, an agreement on valid and enforceable provisions thereby substituting the void, invalid or unenforceable parts.<br></br>
        In case of failure to do so, the void, invalid or unenforceable provisions shall be replaced by the applicable statutory provisions, if so permitted or stated under the applicable law.</p>

        <p>Without prejudice to the above, the nullity, invalidity or the impossibility to enforce a particular provision of these Terms shall not nullify the entire Agreement, unless the severed provisions are essential to the Agreement, or of such importance that the parties would not have entered into the contract if they had known that the provision would not be valid, or in cases where the remaining provisions would translate into an unacceptable hardship on any of the parties.</p>
        <h3 id="governing-law">Governing law</h3>
        <p>These Terms are governed by the law of the place where the Owner is based, as disclosed in the relevant section of this document, without regard to conflict of laws principles.</p>
        <h3 id="venue-of-jurisdiction">Venue of jurisdiction</h3>
        <p>The exclusive competence to decide on any controversy resulting from or connected to these Terms lies with the courts of the place where the Owner is based, as displayed in the relevant section of this document.</p>
      </div>
      <div className="terms-main-section">
        <h2 id="common-provisions">Not Financial Advice</h2>
        <p>All information on this website is publicly available. None of it constitutes financial advice and should under no circumstances be relied upon for investment decisions.</p>
      </div>
    </div>
  )
}
export default Terms;
